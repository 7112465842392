$direction: (
	'row': 'row',
	'row-reverse': 'row-reverse',
	'column': 'column',
	'column-reverse': 'column-reverse',
);

$justify-content: (
	'start': 'flex-start',
	'end': 'flex-end',
	'center': 'center',
	'between': 'space-between',
	'around': 'space-around',
);

$align-items: (
	'start': 'flex-start',
	'end': 'flex-end',
	'center': 'center',
	'baseline': 'baseline',
	'stretch': 'stretch',
);

$align-content: (
	'start': 'flex-start',
	'end': 'flex-end',
	'center': 'center',
	'between': 'space-between',
	'around': 'space-around',
	'stretch': 'stretch',
);

$wrap: (
	'nowrap': 'nowrap',
	'wrap': 'wrap',
	'wrap-reverse': 'wrap-reverse',
);

$shrink: (
	'shrink-0': '0',
	'shrink-1': '1',
);

$grow: (
	'grow-0': '0',
	'grow-1': '1',
);

$fill: (
	'fill': '1 1 auto',
);

@mixin flexProperty($className, $style, $list) {
	@each $property, $value in $list {
		.#{$prefix}-#{$className}-#{$property} {
			#{$style}: #{$value} !important;
		}
	}
}
/***
// Direction
.hub-flex-row
.hub-flex-row-reverse
.hub-flex-column
.hub-flex-column-reverse
// Justify content
.hub-justify-content-start
.hub-justify-content-end
.hub-justify-content-center
.hub-justify-content-between
.hub-justify-content-around
// Align items
.hub-align-items-start
.hub-align-items-end
.hub-align-items-center
.hub-align-items-baseline
.hub-align-items-stretch
//Align Self
.hub-align-self-start
.hub-align-self-end
.hub-align-self-center
.hub-align-self-baseline
.hub-align-self-stretch
// Flex fill
.hub-flex-fill
// Grow and Shrink
.hub-flex-grow-0
.hub-flex-grow-1
.hub-flex-shrink-0
.hub-flex-shrink-1
// Flex wrap
.hub-flex-nowrap
.hub-flex-wrap
.hub-flex-wrap-reverse
// Align Content
.hub-align-content-start
.hub-align-content-end
.hub-align-content-center
.hub-align-content-between
.hub-align-content-around
.hub-align-content-stretch
 */
@include flexProperty('flex', 'flex-direction', $direction);
@include flexProperty('flex', 'flex-wrap', $wrap);
@include flexProperty('flex', 'flex-shrink', $shrink);
@include flexProperty('flex', 'flex-grow', $grow);
@include flexProperty('flex', 'flex', $fill);
@include flexProperty('justify-content', 'justify-content', $justify-content);
@include flexProperty('align-items', 'align-items', $align-items);
@include flexProperty('align-self', 'align-self', $align-items);
@include flexProperty('align-content', 'align-content', $align-content);

/***
// Order from 0 to 12
.hub-order-0
.hub-order-1
...
.hub-order-12
 */
@for $i from 0 through 12 {
	.#{$prefix}-order-#{$i} {
		order: #{$i};
		-ms-flex-order: #{$i};
	}
}
