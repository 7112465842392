$sizes: (
	'2-5': 2.5rem,
	'3': 3rem,
	'6': 6rem,
	'8-5': 8.5rem,
	'10': 10rem,
	50: 50%,
	75: 75%,
	100: 100%,
	auto: auto
);

/***
	hub-w-2-5
	hub-w-3
	hub-w-6
	hub-w-8-5
	hub-w-8-5
	hub-w-10
	hub-w-50
	hub-w-75
	hub-w-100
	hub-w-auto
	hub-h-2-5
	hub-h-3
	hub-h-6
	hub-h-8-5
	hub-h-8-5
	hub-h-10
	hub-h-50
	hub-h-75
	hub-h-100
	hub-h-auto
 */
@each $prop, $abbrev in (width: w, height: h) {
  @each $size, $length in $sizes {
    .#{$prefix}-#{$abbrev}-#{$size} { #{$prop}: $length !important; }
  }
}

.#{$prefix}-max-w-100 { max-width: 100% !important; }
.#{$prefix}-max-h-100 { max-height: 100% !important; }

.#{$prefix}-min-vw-100 { min-width: 100vw !important; }
.#{$prefix}-min-vh-100 { min-height: 100vh !important; }

.#{$prefix}-vw-100 { width: 100vw !important; }
.#{$prefix}-vh-100 { height: 100vh !important; }

.#{$prefix}-max-w-8-5 { max-width: 8.5rem; }
.#{$prefix}-max-vh-50 {max-height: 50vh !important;}
