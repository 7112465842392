ngb-modal-window {
	.hub-expression-modal {
		border: 0 none;
		bottom: 4rem;
		left: 0;
		margin: 0 auto;
		max-width: 29.5rem;
		position: absolute;
		right: 0;
		transition: 1000ms;
	}

	.hub-expression-modal .modal-content {
		border: 0 none;
		padding: 0;
	}

	.hub-expression-modal.hub-expression-modal-lg {
		bottom: unset;
		left: 50%;
		max-height: 34.5rem;
		max-width: 38rem;
		right: unset;
		top: 50%;
		transform: translate(-50%, -50%) !important;
		width: 38rem;
	}

	.hub-expression-modal.hub-expression-modal-multiple.hub-expression-modal-lg {
		max-height: 49.5rem;
	}
}
