$positions: static, relative, absolute, fixed, sticky !default;

/**
  hub-p-absolute
  hub-p-relative
  hub-p-fixed
  hub-p-static
  hub-p-sticky
 */
@each $position in $positions {
	.#{$prefix}-p-#{$position} { position: $position !important; }
}
