@import '../abstracts/variables';

.hub-solution-center-import-working-area {
	@extend #{'.hub-d-flex', '.hub-flex-column', '.hub-pl-0', '.hub-overflow-auto', '.hub-h-100'};
	max-height: calc(100vh - #{$main-header-height});

	.import-header {
		@extend #{'.hub-px-1-5', '.hub-pt-1-5', '.hub-pb-0-5'};
	}

	.import-body {
		@extend #{'.hub-d-flex', '.border-top'};
		height: 100%;
	}

	.filters-bar {
		@extend #{'.hub-pl-0'};
		width: 18.5rem;
	}

	.sorting-and-cards {
		@extend #{'.col', '.hub-d-flex', '.hub-flex-column', '.hub-pr-0'};
	}

	app-hub-sort-bar {
		@extend #{'.hub-mb-1-5'};
	}

	.solution-cards-list-container,
	hub-solution-card {
		@extend #{'.hub-mb-1'};
		// calc(100vh - 12.4rem-working-area - (.hub-mb-1-5-sort-bar-spacer + .hub-mb-1-for-full-cards-view) - import-header.hub-px-1)
		max-height: calc(100vh - 12.4rem - 2.5rem - 2rem);
	}
}
