@import 'src/assets/styles/abstracts/variables';

$nav-tabs-border-color: $bg-flyout-neutral-hub;
$nav-tabs-border-radius: 0;

$nav-tabs-link-hover-border-color: $bg-flyout-neutral-hub;

$nav-tabs-link-active-color: $dark-navigation-hub;
$nav-tabs-link-active-border-color: transparent transparent $dark-navigation-hub;

$nav-link-disabled-color: $gray-outline-disabled-grey-neutral-hub;

.nav-tabs .nav-link {
	color: $secondary-hub;
	font-size: 0.875rem;

	&.active:hover {
		background-color: $bg-flyout-neutral-hub;
	}

	&:hover {
		background-color: $bg-flyout-neutral-hub;
		color: $muted-navigation-hub;
	}
}

/* stylelint-disable */
.tabset--version-second {
	.nav-tabs .nav-link {
		border-radius: unset;
		color: $secondary-hub;
		font-size: 0.875rem;
	}

	.nav-tabs .nav-link.active,
	.nav-tabs .nav-item.show .nav-link {
		border-bottom-width: 0.125rem;
		border-color: transparent transparent $nav-tabs-link-active-color transparent;
	}
}
.tab-pane-h-100 .tab-pane {
	height: 100%;
}
