/* stylelint-disable */
// Override ngb-accordion style for asset test cases view
@import 'src/assets/styles/abstracts/variables';

ngb-accordion {
	>.card {
		border: unset;
		margin-bottom: 0.438rem !important;
		overflow: unset !important;

		>.card-header {
			background-color: #fff;
			border: 0.063rem solid $secondary-hub;
			border-radius: 0.5rem !important;
			height: 5rem !important;
			padding: 0;
			position: relative;

			.btn.btn-link {
				box-shadow: none !important;
				text-decoration: unset !important;
				width: 100%;
				display: flex;
				justify-content: space-between;
			}

			.btn:active,
			.btn:hover,
			.btn:focus,
			.btn:focus-visible {
				text-decoration: unset !important;
				outline: none;
			}

			&:hover {
				.trapezoid {
					background-color: $muted-navigation-hub;
				}
			}
		}

		.card-body {
			padding-top: 0.063rem !important;
		}

		.hub-assets-definition-tests--no-children>.card-header {
			border-color: $gray-outline-disabled-grey-neutral-hub !important;
		}
	}

	&.sftp-accordion {

		.card {
			margin-bottom: 0 !important;
		}

		.card>.card-header {
			border: 0.0625rem solid $gray-line-divider-frame-neutral-hub;
			border-left: 0 none;
			border-radius: 0 !important;
			border-right: 0 none;
			height: auto !important;
		}


		.card.active,
		.card.active .card-header {
			background-color: $light-hub;
		}

		.card>.card-header .btn.btn-link {
			color: #0F0F0F;
			font-family: 'Krub', sans-serif;
			font-weight: 600;
			padding: 0.75rem 2rem;
		}

		.card>.card-header .btn.btn-link i {
			color: $card-icons-neutral-hub;
		}

		.card.invalid-title>.card-header .btn.btn-link {
			color: $danger-hub;

			i {
				color: $danger-hub;
			}
		}

		.card .card-body {
			padding: 0.75rem 2rem !important;
		}
	}

	&.test-case-accordion {
		&.active-panel {
			.card {
				border-color: $primary-hub
			}
		}

		>.card {
			border: 1px solid $gray-line-divider-frame-neutral-hub;
			border-radius: 0.5rem;
			transition: 150ms;
		}

		>.card>.card-header {
			border: unset;
			border-bottom: 1px solid $gray-line-divider-frame-neutral-hub;
			border-radius: 0 !important;
			background-color: transparent;
			padding: 0.5rem 1rem;
			height: unset !important;
		}

		.btn.btn-link {
			padding: 0;
		}

		.card-body {
			padding: 0 1rem;
		}
	}

	&.new-contract-ai-suggestion-accordion {
		&.selected {
			>.card {
				background-color: $disabled-selected-cta-hub;
			}
		}

		&.active-panel {
			.card {
				border-color: $primary-hub
			}
		}

		>.card {
			background-color: $light-hub;
			border: 1px solid $gray-line-divider-frame-neutral-hub;
			border-radius: 0.5rem;
			transition: 150ms;
		}

		>.card>.card-header {
			border: unset;
			border-bottom: 1px solid $gray-line-divider-frame-neutral-hub;
			border-radius: 0 !important;
			background-color: transparent;
			padding: 0.5rem 1rem;
			height: unset !important;
		}

		.btn.btn-link {
			padding: 0;
		}

		.card-body {
			padding: 0 1rem;
		}
	}
}
