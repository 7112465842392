@import 'src/assets/styles/abstracts/variables';

// COMMON styles for module, asset, project overview pages

/***  COMMON html skeleton
 <div class="hub-working-area-overview"  [ngClass]="{
   'close-right-sidebar': isClosedRightSidebar,
   'close-left-sidebar': isClosedLeftSidebar,
   'close-both-sidebars': isClosedRightSidebar && isClosedLeftSidebar
 }">
   <div class="overview-header">
     <h3 class="overview-header-title">TITLE</h3>
   </div>

   <div class="overview-left-sidebar">
     <button class="sidebar-toggle-btn btn icon-chevron-up"</button>
   </div>

   <div class="overview-body"></div>

   <div class="overview-right-sidebar">
     <button class="sidebar-toggle-btn btn icon-chevron-up"</button>
   </div>
 </div>

 [ngClass]="{
   'close-right-sidebar': isClosedRightSidebar,
   'close-left-sidebar': isClosedLeftSidebar,
   'close-both-sidebars': isClosedRightSidebar && isClosedLeftSidebar
*/

$hub-working-area-overview-max-height: calc(100vh - #{$main-header-height});

.hub-working-area-overview {
	display: grid;
	grid-template-areas:
		'overview-header overview-header overview-header'
		'overview-left-sidebar overview-body overview-right-sidebar'
		'overview-left-sidebar overview-body overview-right-sidebar';
	grid-template-columns: 11.25rem 1.6fr 17rem;
	grid-template-rows: 4rem 8rem 1fr;
	height: 100%;
	max-height: $hub-working-area-overview-max-height;
	padding: 0 1.5rem;

	&.hub-mod-large {
		grid-template-rows: 5.5rem 7.5rem 1fr;
	}

	&.hub-canvas-view-active {
		padding-right: 0;
	}
}

.overview-header {
	@extend #{'.border-bottom'};
	grid-area: overview-header;
	padding: 0 0.625rem;

	.hub-canvas-view-active & {
		margin-right: 1.5rem;
	}

	.overview-header-title {
		color: $light-text-hub;
		font-family: 'Krub', serif;
		font-size: 1.25rem;
		font-weight: bold;
		margin: 0;
	}
}

.hub-working-area-overview.close-right-sidebar {
	grid-template-columns: 11.25rem 1.6fr 5rem;
}

.hub-working-area-overview.hide-right-sidebar {
	grid-template-columns: 11.25rem 1.6fr;
}

.hub-working-area-overview.hide-left-sidebar {
	grid-template-columns: 0 1.6fr 0fr;
}

.hub-working-area-overview.close-left-sidebar {
	grid-template-columns: 5rem 1.6fr 17rem;
}

.close-left-hide-right-sidebar {
	grid-template-columns: 5rem 1.6fr;
}

.hub-working-area-overview.close-both-sidebars {
	grid-template-columns: 5rem 1.6fr 5rem;
}

.overview-left-sidebar .sidebar-toggle-btn,
.overview-right-sidebar .sidebar-toggle-btn {
	align-items: center;
	border: 0.063rem solid $secondary-hub;
	border-radius: 50%;
	box-shadow: unset;
	color: $secondary-hub;
	display: block;

	// to align in middle
	display: flex;
	justify-content: center;
	max-height: 1.5rem;
	max-width: 1.5rem;
	outline: 0;
	position: relative;

	[class*='icon-'] {
		font-size: 1.5rem;
	}

	&:focus,
	&.focus {
		box-shadow: none;
	}

	&:hover {
		color: $muted-navigation-hub;
	}

	&:active {
		color: $muted-navigation-hub;
	}

	&:active::after {
		background-color: #e5e8fb;
		border-radius: 50%;
		bottom: -0.25rem;
		content: '';
		left: -0.25rem;
		position: absolute;
		right: -0.25rem;
		top: -0.25rem;
		z-index: -1;
	}
}

.overview-right-sidebar .sidebar-toggle-btn {
	margin-left: auto;
	transition: 150ms;
}

.overview-left-sidebar .sidebar-toggle-btn {
	margin-right: auto;
	transition: 150ms;
}

.overview-left-sidebar {
	border-right: 0.063rem solid $gray-line-divider-frame-neutral-hub;
	grid-area: overview-left-sidebar;
	padding-right: 1rem;
	padding-top: 1rem;
}

// no paddings due to design.
// if need padding -> do it inside component
.overview-right-sidebar {
	border-left: 0.063rem solid $gray-line-divider-frame-neutral-hub;
	grid-area: overview-right-sidebar;

	.btn-close {
		border: unset;
		color: $grey3-methods-incomplete;
		font-size: 1.2rem;
		margin-left: auto;
		padding: 0 0.3rem;
	}

	.btn-close.btn:focus {
		box-shadow: unset;
	}
}

.overview-body {
	grid-area: overview-body;
	overflow: auto;
}
