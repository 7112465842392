@mixin define-colors() {
	@each $name, $color in $text-colors {
		.#{$prefix}-text-#{$name} {
			color: #{$color} !important;
		}
		.#{$prefix}-bg-#{$name} {
			background-color: #{$color} !important;
		}
	}
}

@include define-colors();

.hub-all-solutions-section-bg-color {
	@extend #{'.hub-bg-primary'};
}

.hub-contracts-section-bg-color {
	background-color: $contracts-section-hub;
}

.hub-modules-section-bg-color {
	background-color: $modules-section-hub;
}

.hub-services-section-bg-color {
	background-color: $services-section-hub;
}

.hub-projects-section-bg-color {
	background-color: $projects-section-hub;
}

.hub-connectors-section-bg-color {
	background-color: $connectors-section-hub;
}

.hub-generators-section-bg-color {
	background-color: $generators-section-hub;
}

.hub-text-projects-color {
	color: $projects-section-hub;
}

.hub-text-services-color {
	color: $services-section-hub;
}

.hub-text-methods-color {
	color: $methods-section-hub;
}

.hub-text-modules-color {
	color: $modules-section-hub;
}

.hub-text-disabled-grey-color {
	color: $gray-outline-disabled-grey-neutral-hub;
}

.hub-delete-swatch-bg-color {
	background-color: $delete-swatch-hub;
}

.hub-get-swatch-bg-color {
	background-color: $get-swatch-hub;
}

.hub-patch-swatch-bg-color {
	background-color: $patch-swatch-hub;
}

.hub-put-swatch-bg-color {
	background-color: $put-swatch-hub;
}

.hub-post-swatch-bg-color {
	background-color: $post-swatch-hub;
}
