.tooltip-inner {
	font-size: 0.875rem;
	line-height: 170%;
}

.hub-tooltip-mw-15rem {
	.tooltip-inner {
		max-width: 15rem;
	}
}

.hub-tooltip-large {
	.tooltip-inner {
		max-width: 27rem;
	}
}

.hub-tooltip-medium {
	.tooltip-inner {
		max-width: 20rem;
	}
}

.custom-tooltip {
	.tooltip-inner {
		max-width: max-content;
		width: max-content;
	}
}

.password-icon-tooltip {
	.tooltip-inner {
		max-width: 4rem;
	}
}
