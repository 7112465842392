/**
 * Define font sizes and weights
 *
 * After some thinking about class names, comfort to work with real pixel name part is better than working with rems in names whole the time
 * class name hub-fs-14, hub-fs-16, hub-fs-24, etc.
 *
 * class name hub-fw-normal, hub-fw-500, hub-fw-600, hub-fw-bold, hub-fw-bolder
 */
$font-sizes: (
	'10': ($spacer * .625),
	'12': ($spacer * .75),
	'14': ($spacer * .875),
	'16': $spacer,
	'18': ($spacer * 1.125),
	'20': ($spacer * 1.25),
	'24': ($spacer * 1.5),
	'28': ($spacer * 1.75),
	'32': ($spacer * 2),
	'48': ($spacer * 3),
	'56': ($spacer * 3.5),
	'64': ($spacer * 4),
	'96': ($spacer * 6),
	'112': ($spacer * 7)
);

$font-weights: (
	'normal': 'normal',
	'500': '500',
	'600': '600',
	'bold': 'bold',
	'bolder': 'bolder'
);

$font-transforms: (
	'lowercase': 'lowercase',
	'uppercase': 'uppercase',
	'capitalize': 'capitalize'
);

$font-families: (
	'krub': "'Krub', sans-serif",
	'roboto-medium': "'Roboto Medium', sans-serif",
	'roboto': "'Roboto', sans-serif",
	'roboto-mono': "'RobotoMono Medium', sans-serif"
);

@mixin define-fonts() {
	@each $name, $size in $font-sizes {
		.#{$prefix}-fs-#{$name} {
			font-size: $size !important;
		}
	}
	@each $name, $size in $font-weights {
		.#{$prefix}-fw-#{$name} {
			font-weight: #{$size} !important;
		}
	}
	@each $name, $size in $font-transforms {
		.#{$prefix}-tt-#{$name} {
			text-transform: #{$size} !important;
		}
	}
	@each $name, $size in $font-families {
		.#{$prefix}-ff-#{$name} {
			font-family: #{$size} !important;
		}
	}
}

@include define-fonts();

.#{$prefix}-text-black-50 { color: rgba(#000, .5) !important; }
.#{$prefix}-text-white-50 { color: rgba(#fff, .5) !important; }

$text-aligns: left, right, center, justify !default;
@each $value in $text-aligns {
	.#{$prefix}-text-#{$value} { text-align: $value !important; }
}

.#{$prefix}-text-wrap     { white-space: normal !important; }
.#{$prefix}-text-break {
	word-break: break-word !important; // Deprecated, but avoids issues with flex containers
	word-wrap: break-word !important; // Used instead of `overflow-wrap` for IE & Edge Legacy
}
.#{$prefix}-hub-text-nowrap   { white-space: nowrap !important; }
.#{$prefix}-text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.#{$prefix}-font-italic { font-style: italic !important; }
.#{$prefix}-td-underline {text-decoration: underline;}
.#{$prefix}-td-none {text-decoration: none;}

.#{$prefix}-text-ellipsis-vertical {
	-webkit-box-orient: vertical;
	display: -webkit-box;
	-webkit-line-clamp: 2; // 3 lines ellipsis
	overflow: hidden;
	word-break: break-word;
}
