.hub-icons,
[class*='icon-'] {
	transition: 150ms;

	// PRIMARY
	&.hub-icon-primary {
		border-radius: 50%;
		color: $primary-hub;
	}

	&.hub-icon-primary:hover {
		background-color: $disabled-selected-cta-hub;
		color: $muted-cta-hub;
	}

	&.hub-icon-primary.active,
	&.hub-icon-primary:active {
		background-color: $shade-cta-hub;
		color: $muted-cta-hub;
	}

	// GRADIENT ICON
	&.hub-icon-gradient {
		border-radius: 50%;
		color: #fff;
	}

	&.hub-icon-gradient:hover {
		background-color: $disabled-selected-cta-hub;
		color: $muted-cta-hub;
	}

	&.hub-icon-gradient.active,
	&.hub-icon-gradient:active {
		background-color: $shade-cta-hub;
		color: $muted-cta-hub;
	}

	// SECONDARY
	&.hub-icon-secondary {
		border-radius: 50%;
		color: $secondary-hub;
	}

	&.hub-icon-secondary:hover {
		background-color: $bg-sidenav-neutral-hub;
		color: $muted-navigation-hub;
	}

	&.hub-icon-secondary.active,
	&.hub-icon-secondary:active {
		background-color: $main-shade-hub;
		color: $muted-navigation-hub;
	}

	// WHITE
	&.hub-icon-white {
		border-radius: 50%;
		color: #fff;
	}

	&.hub-icon-white:hover {
		color: #fff;
	}

	&.hub-icon-white.active,
	&.hub-icon-white:active {
		color: #fff;
	}

	// DANGER
	&.hub-icon-danger {
		border-radius: 50%;
		color: $danger-hub;
	}

	&.hub-icon-danger:hover {
		color: $danger-hub;
	}

	&.hub-icon-danger.active,
	&.hub-icon-danger:active {
		background-color: #e5e8fb;
		color: $danger-hub;
	}

	&.hub-icon-disabled {
		color: $message-text-hub;
	}

	&.hub-icon-disabled-light {
		color: $gray-outline-disabled-grey-neutral-hub;
	}

	// WHITE ON DARK BG
	&.hub-icon-white-on-dark-bg {
		border-radius: 50%;
		color: #fff;
	}

	&.hub-icon-white-on-dark-bg:hover {
		background-color: $bg-sidenav-neutral-hub;
		color: $secondary-hub;
	}

	&.hub-icon-white-on-dark-bg:active {
		background-color: $main-shade-hub;
		color: $muted-navigation-hub;
	}

	&.bg-size-2rem {
		background-size: 2rem;
	}
}
