.hub-cursor-p {
	cursor: pointer !important;
}

.hub-cursor-d {
	cursor: default !important;
}

.width-max-content {
	width: max-content;
}

.hub-text-lh-100 {
	line-height: 100%;
}

.hub-text-lh-110 {
	line-height: 110%;
}

.hub-text-lh-120 {
	line-height: 120%;
}

.hub-text-lh-130 {
	line-height: 130%;
}

.hub-text-lh-150 {
	line-height: 150%;
}

.hub-text-lh-160 {
	line-height: 160%;
}

.hub-text-lh-170 {
	line-height: 170%;
}

.hub-text-lh-250 {
	line-height: 250%;
}

.hub-text-lh-16px {
	line-height: 1rem;
}

.hub-text-lh-24px {
	line-height: 1.5rem;
}

.hub-text-lh-28px {
	line-height: 1.75rem;
}

.hub-text-lh-30px {
	line-height: 1.875rem;
}

.hub-text-lh-32px {
	line-height: 2rem;
}

.hub-text-lh-40px {
	line-height: 2.5rem;
}

.hub-link {
	color: $secondary-hub;
	font-weight: bold;

	&:hover {
		color: $muted-navigation-hub;
		text-decoration: none;
	}

	&:active {
		color: $dark-navigation-hub;
	}
}

.hub-hide-show-password-icon {
	position: absolute;
	right: 0.7rem;
	top: 50%;
	transform: translateY(-50%);
	z-index: 3;
}

.hub-color-assets-section {
	color: $assets-section-hub;
}

.hub-color-border-dash-grey {
	color: $border-dash-grey
}

.hub-color-message-text {
	color: $message-text-hub;
}

.hub-color-muted-nav {
	color: $muted-navigation-hub;
}

.hub-text-underline {
	text-decoration: underline;
}

.hub-text-of-ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.hub-white-space-break-spaces {
	white-space: break-spaces;
}

.hub-overflow-wrap-break-word {
	overflow-wrap: break-word;
}

.hub-overflow-wrap-anywhere {
	overflow-wrap: anywhere;
}

.hub-zi-1 {
	z-index: 1;
}

.hub-outline-0 {
	outline: 0 none;
}

.hub-search-highlight {
	background: #fff500;
	font-size: inherit;
	font-weight: inherit;
	line-height: inherit;

	&.light {
		background: none;
		color: #000;
	}
}

.hub-grayscale-disabled {
	filter: grayscale(1);
}

.hub-small-badge {
	height: 1.25rem;
}
