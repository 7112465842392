/**
* Bootstrap overrides
*/

// https://getbootstrap.com/docs/4.0/utilities/colors/#color
$theme-colors: (
	'primary': $primary-hub,
	'secondary': $secondary-hub,
	'success': $success-hub,
	'danger': $danger-hub,
	'light': $light-hub,
	'dark': $dark-hub,
);

$font-family-base: 'Roboto', sans-serif; // main boostrap font family

/******dropdown styles********/

$dropdown-link-color: $light-text-hub;

/******styles for dropdown secondary - by default ************/
$dropdown-link-hover-color: $muted-navigation-hub;
$dropdown-link-active-color: $muted-navigation-hub;
$dropdown-link-hover-bg: #fff;
$dropdown-link-active-bg: $bg-sidenav-neutral-hub;
/*************************************************************/

$dropdown-item-padding-y: 0.75rem;
$dropdown-item-padding-x: 0.5rem;
$dropdown-padding-x: 0;
$dropdown-padding-y: 0;
$dropdown-border-width: 0.063rem;
$dropdown-border-color: #cbcccd;
$dropdown-border-radius: 0;
$dropdown-spacer: 0;
$dropdown-inner-border-radius: 0;
$dropdown-border-radius: 0 0 0.25rem 0.25rem;
$dropdown-font-size: 0.875rem;

// styles for ng-select in `_ng-select.scss`
// ng-select should appear same as bootstrap dropdown

.api-keys-dropdown {
	.dropdown-menu {
		left: unset !important;
		right: 0 !important;
		top: 2.5rem !important;
		transform: unset !important;
	}

	.dropdown-toggle {
		padding: 0 !important;
	}

	.dropdown-toggle .btn {
		padding: 0 !important;
	}
}

.dropdown-menu {
	.btn {
		font-size: $dropdown-font-size;
	}
}

.dropdown-item {
	border-bottom: 0.063rem solid #cbcccd !important;
	border-radius: 0 !important;
	font-weight: bold !important;
	transition: 150ms;

	&:last-child {
		border-bottom: 0 !important;
	}
}

$tooltip-bg: $dark-hub;
$tooltip-arrow-color: $dark-hub;
$tooltip-font-size: 0.875rem;

/****************************/

/****** FORM ********/

$input-border-radius: 0.25rem;
$input-border-width: 0.063rem;
$input-border-color: #cbcccd;
$input-bg: #fff;
$input-color: $dark-hub;
$input-focus-border-color: $muted-navigation-hub;
$input-height: 2rem;
$input-padding-y: 0.25rem;
$input-padding-x: 0.5rem;

.form-group {
	label {
		color: #333;
		font-size: 1rem;
		margin-bottom: 0.25rem;
	}

	.invalid-feedback {
		color: $danger-hub;
	}

	.valid-feedback {
		color: $success-hub;
	}

	.input-group-prepend,
	.input-group-append {
		height: $input-height;
	}
}

.form-control {
	background-color: #fff;
	background-image: unset !important;
	border: 0.063rem solid #cbcccd;
	border-radius: 0.25rem;
	box-shadow: none !important;
	color: $dark-hub;
	resize: none;
}

.form-control:hover {
	border-color: $secondary-hub;
}

.form-control.success {
	border-color: $success-hub;
}

.form-control.fail,
.form-control:invalid,
.form-control.ng-invalid.ng-touched {
	border-color: $danger-hub;
}

.form-control:focus,
.form-control:active,
.form-control.active,
.form-control.focus {
	border-color: $muted-navigation-hub;
}

/****************************/

/****** TOAST ********/
$toast-padding-x: 1rem;
$toast-padding-y: 0.5rem;

.toast-body {
	background-color: #333;
	border-radius: 0.5rem;
	color: #fff;
	font-size: 1rem;
	padding: $toast-padding-y $toast-padding-x !important;
}

/****************************/

/****** MODAL ********/

$modal-backdrop-bg: #fff;
$modal-lg: 38rem;

/****************************/

/****** BUTTON GROUP ********/

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
	margin-left: 0 !important;
}

/****************************/

$text-muted: $light-text-hub;

/*** SPINNER ***/
.custom-spinner {
	.modal-content {
		background: transparent;
		border: 0;
	}
}

/*** GRID ***/
$grid-breakpoints: (
	'xs': 0,
	// Extra small screen or phone
	'sm': 36rem,
	// 576px, // Small screen or phone
	'md': 48rem,
	// 768px, // Medium screen or tablet
	'lg': 62rem,
	// 992px, // Large screen or desktop
	'xl': 75rem,
	// 1200px // Extra large screen or wide desktop
	'xxl': 90rem,
	// 1440px // Extra large screen or wide desktop
);

.badge {
	padding: 0.321rem 0.5em;
}

.badge-light {
	border: 0.0625rem solid $input-border-color;
}

/*** TABLE ***/

$table-accent-bg: $light-hub;

.retry-policy-input-fix-height {
	input {
		height: 2.25rem;
	}
}
