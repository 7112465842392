@import 'src/assets/styles/abstracts/variables';

.hub-drop-area-wrapper .hub-drop-area {
	position: relative !important;
	z-index: 1111;

	.hub-drop-area-container {
		opacity: 1;
		z-index: 1011;
	}

	.ngx-monaco-editor {
		display: none;
	}
}

.hub-drop-area-container {
	align-items: center;
	background: #f7f7f7;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	opacity: 0;
	position: absolute;
	top: 0;
	transition: opacity 0.5s;
	width: 100%;
	z-index: -1;

}


