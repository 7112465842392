ngb-modal-window {

	/* stylelint-disable */
	.modal-dialog {
		&.no-padding {
			.modal-content {
				padding: 0;
			}
		}

		&.full-screen {
			max-width: calc(98vw);
		}

		&.large-screen {
			max-width: calc(55vw);
		}

		&.sticky-footer {
			.modal-content {
				padding-bottom: 0;
			}

			.modal-footer {
				position: sticky;
				bottom: 0;
				background: #fff;
				margin-left: -2rem;
				margin-right: -2rem;
				padding-bottom: 2rem;
				padding-left: 2rem;
				padding-right: 2rem;
			}
		}
	}

	/* stylelint-enable */

	.modal-content {
		box-shadow: 0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.08);
		padding: 2rem;
	}

	.modal-header,
	.modal-body,
	.modal-footer {
		padding: 0;
	}

	.modal-header {
		padding-bottom: 1rem;
	}

	.modal-footer {
		padding-top: 2rem;
	}

	.modal-header,
	.modal-footer {
		border: unset;
	}

	.modal-header .modal-title {
		font-family: 'Krub', sans-serif;
	}

	.modal-footer>* {
		margin: 0;
	}
}

.sft-modal-dialog-class {
	.modal-header {
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.modal-content {
		padding: 2rem 0;

		.modal-body {
			margin-right: 0;
			min-height: 25rem;
			overflow: hidden;
			padding: 0 0 0.0625rem;
		}

		.modal-footer {
			padding: 1.375rem 2rem 0;
		}
	}
}

.preview-screen-modal .modal-xl {
	max-width: unset;
	width: fit-content;
}
