/* This class is used for showing the label box on cards like solution-card or
app-create-field-wizard option .hub-card-desc
Usually this box shows messages and info sent by the server ( coming soon, new solution ) */

.hub-card-label-box {
	@extend #{'.hub-fs-12', '.hub-py-0-25', '.hub-px-0-5', '.hub-mb-0-25', '.border'};
	border-radius: 0.11rem;
	color: $methods-section-hub;
	cursor: default;
	font-weight: 500;
	height: 1.5rem;
}
