.hub-status-bar {
	background-color: $light-text-hub;
	height: 3rem;
	max-height: 0;
	opacity: 0;
	overflow: hidden;
	transition: 500ms;

	&.show {
		max-height: 3rem;
		opacity: 1;
	}
}

.hub-status-bar .progress-bar-wrapper {
	background-color: $border-dash-grey;
	height: 0.5rem;
	overflow: hidden;

	.progress {
		background-color: $loading-generator-hub;
		height: 100%;
		transition: 500ms;
		width: 100%;
	}

	.progress.error {
		background-color: $danger-hub;
	}
}

.hub-status-bar .progress-line-animation {
	animation-duration: 2s;
	animation-iteration-count: infinite;
	animation-name: hub-status-bar-progress-line;
}

@keyframes hub-status-bar-progress-line {
	0% {
		transform: translateX(-100%);
		width: 0%;
	}

	100% {
		transform: translateX(100%);
		width: 100%;
	}
}
