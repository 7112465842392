@mixin spacings($className, $property) {
	@each $size, $length in $spacers-list {
		.#{$prefix}-#{$className}-#{$size} { #{$property}: $length !important; }
		.#{$prefix}-#{$className}t-#{$size},
		.#{$prefix}-#{$className}y-#{$size} {
			#{$property}-top: $length !important;
		}
		.#{$prefix}-#{$className}r-#{$size},
		.#{$prefix}-#{$className}x-#{$size} {
			#{$property}-right: $length !important;
		}
		.#{$prefix}-#{$className}b-#{$size},
		.#{$prefix}-#{$className}y-#{$size} {
			#{$property}-bottom: $length !important;
		}
		.#{$prefix}-#{$className}l-#{$size},
		.#{$prefix}-#{$className}x-#{$size} {
			#{$property}-left: $length !important;
		}

		// Negative margins only
		@if "#{$className}" == "m" and "#{$size}" != "0" {
			.#{$prefix}-#{$className}-n#{$size} { #{$property}: -$length !important; }
			.#{$prefix}-#{$className}t-n#{$size},
			.#{$prefix}-#{$className}y-n#{$size} {
				#{$property}-top: -$length !important;
			}
			.#{$prefix}-#{$className}r-n#{$size},
			.#{$prefix}-#{$className}x-n#{$size} {
				#{$property}-right: -$length !important;
			}
			.#{$prefix}-#{$className}b-n#{$size},
			.#{$prefix}-#{$className}y-n#{$size} {
				#{$property}-bottom: -$length !important;
			}
			.#{$prefix}-#{$className}l-n#{$size},
			.#{$prefix}-#{$className}x-n#{$size} {
				#{$property}-left: -$length !important;
			}
		}
	}
	// Auto margins
	@if "#{$className}" == "m" {
		.#{$prefix}-#{$className}-auto { margin: auto !important; }
		.#{$prefix}-#{$className}t-auto, .#{$prefix}-#{$className}y-auto {margin-top: auto !important;}
		.#{$prefix}-#{$className}r-auto, .#{$prefix}-#{$className}x-auto {margin-right: auto !important;}
		.#{$prefix}-#{$className}b-auto, .#{$prefix}-#{$className}y-auto {margin-bottom: auto !important;}
		.#{$prefix}-#{$className}l-auto, .#{$prefix}-#{$className}x-auto {margin-left: auto !important;}
	}
}

// Set margins
@include spacings('m', 'margin');

// Set paddings
@include spacings('p', 'padding');
