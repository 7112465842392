/******
  styles for dropdown secondary is default
  - overriden using bootstrap variables
  - in vendor-extensions/_bootstrap.scss

  for primary styles defined css class
************/

.hub-dropdown-primary {
	.dropdown-item:hover {
		background-color: #fff !important;
		color: $muted-cta-hub !important;
	}

	.dropdown-item:active,
	.dropdown-item.active {
		background-color: $disabled-selected-cta-hub !important;
		color: $muted-cta-hub !important;
	}
}

.form-control:disabled,
.form-control.disabled {
	background-color: #f9f9f9;
	border-color: #cbcccd;
}

// override bootstrap tooltip in get-started-step-one cmp
.get-started-step-one-tooltip {
	.tooltip-inner {
		max-width: 21.25rem; // 340px
		padding: 1rem 1.5rem;
	}
}
