/* stylelint-disable */
@import 'src/assets/styles/abstracts/variables';

.code-editor .monaco-editor .margin {
	background-color: $light-hub !important;
}

.code-editor .monaco-editor .view-lines {
	background-color: $light-hub !important;
}

.code-editor .monaco-editor .view-line {
	z-index: 1000;
}

.code-editor .monaco-editor .line-numbers,
.code-editor .monaco-editor .line-numbers.active-line-number {
	color: #000;
}

.code-editor div {
	line-height: normal;
	font-family: unset;
}

.code-editor.failed-test {
	.monaco-editor .view-line .mtk7,
	.monaco-editor .view-line .mtk5,
	.monaco-editor .view-line .mtk20 {
		color: $tamarillo;
	}
}

.code-editor .monaco-editor .view-line .mtk20 {
	color: $light-text-hub;
}

.code-editor .monaco-editor .view-line .mtk5 {
	color: $tamarillo;
}

.code-editor .monaco-editor .selected-text {
	z-index: 999;
}

.code-editor .monaco-editor .cldr.codicon.codicon-folding-expanded {
	background-image: url('../../images/svg/caret-down-blue.svg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	opacity: 1;

	&::before {
		content: unset;
	}
}

.code-editor .monaco-editor .cldr.codicon.codicon-folding-collapsed {
	background-image: url('../../images/svg/caret-down-blue.svg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	opacity: 1;
	transform: rotate(-90deg);

	&::before {
		content: unset;
	}
}

.code-editor .monaco-editor .cldr.codicon.codicon-chevron-down::before {
	content: unset;
}

.code-editor .monaco-editor .cldr.codicon.codicon-chevron-right::before {
	content: unset;
}

.monaco-editor .monaco-scrollable-element > .scrollbar {
	margin-right: 0.25rem;
}

.monaco-editor .monaco-scrollable-element > .scrollbar > .slider {
	background: $gray-outline-disabled-grey-neutral-hub !important;
	border-radius: 0.125rem;
}
